import apollo from "@/plugins/apollo";
import { DATA_HEADER_CAMPAING } from "@/graphql/Dashboard/Header";
import { GET_TABLE_CPM } from "@/graphql/Dashboard/TableCPM";
import { GET_TABLE_CPC } from "@/graphql/Dashboard/TableCPC";
import { GET_TABLE_CPV } from "@/graphql/Dashboard/TableCPV";
import { GET_TABLE_DAILY_DELIVERY } from "@/graphql/Dashboard/DailyDelivery";
import { GET_TOKEN } from "@/graphql/Auth/Token";
import { GET_PLACEMENTS } from "@/graphql/Dashboard/Placements";
import { GET_CREATIVE_HEATMAPS } from "@/graphql/Dashboard/creativeHeatmap";
import { tableMap, tableMapSite } from "@/graphql/Dashboard/tableMap";
import { pressVehicle } from "@/graphql/Dashboard/PressVehicleTable";
import { map } from "@/graphql/Dashboard/map";
import { chartVerification } from "@/graphql/Dashboard/chartVerification";
import { domainOverlay } from "@/graphql/Dashboard/domainOverlay";
import { wrapperWorld } from "@/graphql/Dashboard/wrapperWorld";
import { tableWorld } from "@/graphql/Dashboard/tableWorld";
import { scroll } from "@/graphql/Dashboard/scroll";
import { GET_PURCHASES_TYPES_TOTAL } from "@/graphql/Dashboard/purchaseTypeData";

const sitesData = async (variables) => {
  const response = await apollo.query({
    query: GET_SITES,
    variables,
  });
  return response.data.campaign.sites;
};

const getDataCampaign = async (variables) => {
  const response = await apollo.query({
    query: DATA_HEADER_CAMPAING,
    variables,
  });
  return response.data.campaign;
};

const CPM_getData = async (variables) => {
  const response = await apollo.query({
    query: GET_TABLE_CPM,
    variables,
  });
  return response.data.campaign;
};

const getDataToken = async (variables) => {
  const response = await apollo.query({
    query: GET_TOKEN,
    variables,
  });
  return response.data.user_token;
};

const CPC_getData = async (variables) => {
  const response = await apollo.query({
    query: GET_TABLE_CPC,
    variables,
  });
  return response.data.campaign;
};

const CPV_getData = async (variables) => {
  const response = await apollo.query({
    query: GET_TABLE_CPV,
    variables,
  });
  return response.data.campaign;
};

const Daily_Delivery_getData = async (variables) => {
  const response = await apollo.query({
    query: GET_TABLE_DAILY_DELIVERY,
    variables,
  });
  return response.data.campaign;
};

const getDataGeneralTable = async (variables) => {
  const response = await apollo.query({
    query: GeneralTable,
    variables,
  });
}

const getPlacements = async (variables) => {
  const response = await apollo.query({
    query: GET_PLACEMENTS,
    variables,
  });
  return response.data.campaign.placements;
};

const getCreativeHeatmaps = async (variables) => {
  const response = await apollo.query({
    query: GET_CREATIVE_HEATMAPS,
    variables,
  });
  return response.data.creatives;
};

const getTableMap = async (variables) => {
  const response = await apollo.query({
    query: tableMap,
    variables,
  });
  return response.data.campaign;
};

const getTableMapSite = async (variables) => {
  const response = await apollo.query({
    query: tableMapSite,
    variables,
  });
  
  return response.data.campaign.sites[0];
};

const getVehicle = async (variables) => {
  const response = await apollo.query({
    query: pressVehicle,
    variables,
  });
  return response.data;
};

const getMap = async (variables) => {
  const response = await apollo.query({
    query: map,
    variables,
  });
  return response.data;
};

const getChartVerification = async (variables) => {
  const response = await apollo.query({
    query: chartVerification,
    variables,
  });
  return response.data.campaign;
};
const getDomainOverlay = async (variables) => {
  const response = await apollo.query({
    query: domainOverlay,
    variables,
  });
  return response.data.campaign;
};

const getWrapperWorld = async (variables) => {
  const response = await apollo.query({
    query: wrapperWorld,
    variables,
  });
  return response.data;
};

const getTableWorld = async (variables) => {
  const response = await apollo.query({
    query: tableWorld,
    variables,
  });
  return response.data;
};

const getScroll = async (variables) => {
  const response = await apollo.query({
    query: scroll,
    variables,
  });
  return response.data.campaign.sites;
};

const getGET_PURCHASES_TYPES_TOTAL = async (variables) => {
  const response = await apollo.query({
    query: GET_PURCHASES_TYPES_TOTAL,
    variables,
  });
  return response.data.campaigns;
};

export {
  sitesData,
  getDataCampaign,
  CPM_getData,
  CPC_getData,
  CPV_getData,
  Daily_Delivery_getData,
  getDataToken,
  getPlacements,
  getCreativeHeatmaps,
  getTableMap,
  getTableMapSite,
  getVehicle,
  getMap,
  getChartVerification,
  getDomainOverlay,
  getWrapperWorld,
  getTableWorld,
  getScroll,
  getGET_PURCHASES_TYPES_TOTAL,
  getDataGeneralTable
};

//TO-DO - VERIFICAR TODAS ESSAS REQUISIÓES E PASSAR PARA O SERVICE EM SRC
