import Dashboard from "@/views/Dashboard/Dashboard.vue"

export default {
    path: '/dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
        {
            path : 'campaigns',
            name: 'Campaigns',
            meta: { accessRoute: true },
            component: () => import('@/views/Dashboard/AllCampaigns.vue'),
        },
        {
            path : 'campaign/:campaign_id',
            name: 'SingleCampaign',
            meta: { accessRoute: true },
            component: () => import('@/views/Dashboard/SingleCampaign.vue'),
        },
        {
            path : 'campaign/:campaign_id/site/:site_id',
            name: 'SitesView',
            component: () => import('@/views/Dashboard/Sites.vue'),
        },
        {
            path : 'notifications',
            name: 'NotificationView',
            component: () => import('@/views/Dashboard/Notification.vue'),
        }
    ]
}
