import gql from "graphql-tag";

const GET_STATUS_SITE = gql`
query GetStatusSite($filter: String, $campaignId: Int!,) {
  site (filter: $filter) {
    paused_placements_status(campaign_id: $campaignId)
  }
}
`;

const TABLE_PER_DAY_SITES = gql`
query GetPurchaseTypePerDayInCampaign($filter: String, $filterSite: String, $campaignId: Int!, $startDatetime: Int, $endDatetime: Int, $limit: Int, $group: String) {
  campaign (filter: $filter) {
    sites(filter: $filterSite, limit: $limit) {
      site_id
      site_name
      purchases {
        cpc {
          quantity
        }
        cpm {
          quantity
        }
        cpv {
          quantity
        }
      }
      data_by_date_purchase(campaign_id: $campaignId, start_datetime: $startDatetime, end_datetime: $endDatetime, group: $group)
    }
  }
}
`;

const TABLE_PER_DAY_CHANNELS = gql`
query GetPurchaseTypePerDayInSite($startDatetime: Int, $endDatetime: Int, $filter: String, $campaignId: Int) {
  site (filter: $filter) {
    site_id
    site_name
    purchases(campaign_id: $campaignId, start_datetime: $startDatetime, end_datetime: $endDatetime) {
      cpm {
        channels
        quantity
        total_data {
          views
          clicks
          blocklists
          valids
          completes
          viewability
          impressions
        }
      }
      cpc {
        channels
        quantity
        total_data {
          views
          clicks
          blocklists
          valids
          completes
          viewability
          impressions
        }
      }
      cpv {
        channels
        quantity
        total_data {
          views
          clicks
          blocklists
          valids
          completes
          viewability
          impressions
        }
      }
    }
  }
}
`;

const TABLE_PER_DAY_PLACEMENTS = gql`
  query GetChannel($filter: String, $startDatetime: Int, $endDatetime: Int) {
    channel(filter: $filter) {
      channel_id
      placements {
        placement_id
        placement_name
        placement_purchase_qty
        format {
          format_size_width
          format_size_height
          format_name
        }
        total_data(start_datetime: $startDatetime, end_datetime: $endDatetime) {
          clicks
          blocklists
          valids
          impressions
          views
          completes
          viewability
          viewables
        }
        placement_status {
          placement_status_id
          status_descr
        }
        purchase_type {
          purchase_type_format
          purchase_type_name
        }
      }
    }
  }
`;

const TABLE_PER_DAY_CREATIVES = gql`
  query GetPlacement($filter: String, $startDatetime: Int, $endDatetime: Int) {
    placement(filter: $filter) {
      placement_id
      creatives {
        creative_group {
          creative_group_id
          group_name
        }
        total_data(start_datetime: $startDatetime, end_datetime: $endDatetime) {
          impressions
          clicks
          views
          blocklists
          valids
          starts
          completes
          viewability
          viewables
        }
        placement {
          purchase_type {
            purchase_type_format
          }
        }
      }
    }
  }
`;

export {
  GET_STATUS_SITE,
  TABLE_PER_DAY_CHANNELS,
  TABLE_PER_DAY_PLACEMENTS,
  TABLE_PER_DAY_CREATIVES,
  TABLE_PER_DAY_SITES
};
