import gql from 'graphql-tag'

export const READ_NOTIFICATION= gql`
mutation readNotification($content: NotificationReadContent!) {
  createNotificationRead(content: $content) {
    code
    data
    success
    message
  }
}`