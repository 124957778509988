import gql from "graphql-tag";

export const scroll = gql`
query GetScrollInCampaign($campaignId: Int!, $filter: String, $sitesFilter2: String, $startDatetime: Int, $endDatetime: Int) {
  campaign(filter: $filter) {
    sites(filter: $sitesFilter2) {
      site_id
      site_name
      offsets(campaign_id: $campaignId, start_datetime: $startDatetime, end_datetime: $endDatetime) {
        _id {
          x
          y
        }
        value
      }
    }
  }
}
`;
