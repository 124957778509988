import gql from "graphql-tag";

export const tableWorld = gql`
  query GetLocationWorld(
    $filter: String
    $sitesFilter2: String
    $campaignId: Int!
    $startDatetime: Int
    $endDatetime: Int
    $group: String
  ) {
    campaign(filter: $filter) {
      sites(filter: $sitesFilter2) {
        site_id
        site_name
        locations(
          campaign_id: $campaignId
          start_datetime: $startDatetime
          end_datetime: $endDatetime
          group: $group
        ) {
          _id {
            country
          }
          views
          impressions
          clicks
        }
      }
    }
  }
`;