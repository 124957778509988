import gql from "graphql-tag";

export const tableMap = gql`
  query GetLocationsInCampaign($filter: String, $match: String, $group: String, $startDatetime: Int, $endDatetime: Int) {
    campaign(filter: $filter) {
      locations(match: $match, group: $group, start_datetime: $startDatetime, end_datetime: $endDatetime) {
        _id {
          country
          region
        }
        clicks
        views
        impressions
      }
      total_data(start_datetime: $startDatetime, end_datetime: $endDatetime) {
        impressions
        views
        clicks
      }
    }
  }
`;

export const tableMapSite = gql`
query GetLocationSitesInCampaign($filter: String, $sitesFilter2: String, $campaignId: Int!, $locationsMatch2: String, $locationsGroup2: String, $locationsStartDatetime2: Int, $locationsEndDatetime2: Int, $totalDataCampaignId2: Int!) {
  campaign(filter: $filter) {
    sites(filter: $sitesFilter2) {
      locations(campaign_id: $campaignId, match: $locationsMatch2, group: $locationsGroup2, start_datetime: $locationsStartDatetime2, end_datetime: $locationsEndDatetime2) {
        _id {
          country
          region
        }
        clicks
        views
        impressions
      }
      total_data(campaign_id: $totalDataCampaignId2) {
        impressions
        views
        clicks
      }
    }
  }
}
`;
