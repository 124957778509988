<template>
  <v-app>
    <Toolbar />
    <v-card min-height="calc(75vh)">
      <router-view />
    </v-card>
    <Footer />
  </v-app>
</template>

<script>
import Toolbar from "@/components/Toolbar/Toolbar.vue";
import Footer from "@/components/Footer/Footer.vue";

export default {
  name: "App",

  components: {
    Toolbar,
    Footer,
  },
};
</script>

<style lang="scss" src="@/scss/general.scss"></style>
