import gql from "graphql-tag";

export const GET_SESSIONS_SITE = gql`
query GetSessionsInCampaign($filter: String, $filterSite: String, $campaignId: Int!) {
  campaign(filter: $filter) {
    campaign_id
    campaign_name
    sites(filter: $filterSite) {
      site_id
      site_name
      sessions(campaign_id: $campaignId) {
        requests
        _id {
          session
        }
      }
    }
  }
}
`;
