import gql from 'graphql-tag'

export const GET_TABLE_REPORTS = gql`
query GetReports($campaignId: Int, $siteId: Int) {
  reports(campaign_id: $campaignId, site_id: $siteId){
    _id
    report
    date_start
    date_end
    status
    campaign_id
    site_id
    emails
    user_name
    user_id
    session_id
    url
    datetime
    percent
    life_check
    min
    }
}`