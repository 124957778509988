import gql from 'graphql-tag'

export const GET_BROWSER = gql` 
query GetBrowserInCampaign($filter: String, $group: String, $startDatetime: Int, $endDatetime: Int) {
  campaign(filter: $filter) {
    browsers (
        group: $group
        start_datetime: $startDatetime
        end_datetime: $endDatetime
      ) {
      _id {
        os_type
      }
      clicks
      impressions
      views
    }
  }
}`

export const GET_BROWSER_SITE = gql` 
query GetBrowserInSite($filter: String, $campaignId: Int!, $group: String, $startDatetime: Int, $endDatetime: Int) {
  site(filter: $filter) {
    browsers(campaign_id: $campaignId, group: $group, start_datetime: $startDatetime, end_datetime: $endDatetime) {
      _id {
        os_type
      }
      clicks
      impressions
      views
    }
  }
}`
