import gql from "graphql-tag";

export const sensitiveUrlTable = gql`
  query GetUrlsSensitive($campaignId: Int!, $filter: String, $filterSite: String, $campaignFilter2: String, $group: String, $startDatetime: Int, $endDatetime: Int) {
  campaign(filter: $campaignFilter2) {
    sites(filter: $filterSite) {
      site_id
      site_name
      urls(campaign_id: $campaignId, filter: $filter, group: $group, start_datetime: $startDatetime, end_datetime: $endDatetime) {
        views
        impressions
        _id {
          category
        }
      }
    }
  }
}
`;