import { round } from "lodash"

const formatError = message => {
    const messageSplit = message.split(':')
    return messageSplit[messageSplit.length - 1].trim()
}

const calculatePercent = (value, total) => {
    return (value / total) * 100
}

export const alphabeticalOrder = (array) => {
    return [...array].sort((a, b) => a.localeCompare(b))
}

export const sumArray = (value) => {
    let result = 0
    if(value.length > 1){
        result = value.reduce((a, b) => {
            return a + b
        }, 0)
    }else if(value.length == 1){
        result = value[0]
    }
    return result
}

export const formatUnixTimeStamp = (value) => {
    let timeStamp = Math.floor(new Date(value).getTime()/1000).toFixed(0)
    return timeStamp
}
export const reverseTimestamp = (timestamp) => {
    // Converte o valor do timestamp em milissegundos multiplicando por 1000
    const milliseconds = timestamp * 1000;
  
    // Cria uma nova instância de Date usando os milissegundos
    const date = new Date(milliseconds);
  
    // Retorna a data como uma string ou objeto Date, conforme necessário
    return date;
  }
export const calcViewabilityVCR = (viewablesTotals, impressionsTotals) => {
    let result = impressionsTotals > viewablesTotals ? (viewablesTotals / impressionsTotals) : (impressionsTotals / viewablesTotals);

    if(result){
        return (result * 100).toFixed(0)
    }else{
        return result = 0
    }
}

export const invertDate = (date) => {
    let dateInvert = date.split('/')
    return `${dateInvert[2]}-${dateInvert[1]}-${dateInvert[0]}`
}


export const calcValid = (impressions, views, blocklist) => {
    let result = (impressions + views) - blocklist
    if(result){
        return result
    }else{
        return result = 0
    }
}

export const calcHandedOut = (views, blocklist) => {
    return views + blocklist
}

export const camelize = (str) => {
    if (typeof str !== "undefined" && str) {
        if(str.length > 4){
            return str.replace(/^[a-z]|[A-Z]|[A-zÀ-ÖØ-öø-ÿ]/g, function (v, i) {
                let chr = i === 0 ? v.toUpperCase() : " " + v.toLowerCase();
            return chr.trim();
            });
        }else{
            return str.replace(/^[a-z]|[A-Z]|[A-zÀ-ÖØ-öø-ÿ]/g, function (v, i) {
                let chr = i === 0 ? v.toUpperCase() : " " + v.toUpperCase();
            return chr.trim();
            });
        }
    } else {
        return str;
    }
    }

export const format_date = (timeStamp, monthSize, monthYear) => {    
    let timeStampDate = Math.floor((timeStamp) * 1000)
    const date = new Date( Number(timeStampDate) );
    const dateFormatted = date.toLocaleDateString("pt-br", {
        day: "numeric",
        month: monthSize,
        year: monthYear
    })
    return dateFormatted
}

export const formatNumber = (value) => {
    if(!value){
      return '-'
    }else{
      return parseInt(value).toLocaleString('pt-br')
    }
}

export const formatNumberPercent = (value) => {
    if(!value){
      return 0
    }else{
      return parseInt(round(value * 100)).toLocaleString("pt-br")
    }
}

export const truncate = ( str, length ) => {
    if(str && str.length > length){
      return str.substring(0, length) + "..."
    }else{
      return str
    }
}

export {
    formatError,
    calculatePercent
}