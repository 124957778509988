import apollo from "@/plugins/apollo";
import { GET_SITES } from "@/graphql/Dashboard/Sites";
import { COUNT_CAMPAINGS } from "@/graphql/Dashboard/CountCampaigns";
import { GET_ALL_CAMPAINGS } from "@/graphql/Dashboard/AllCampaigns";

const getSitesData = async (variables) => {
  const response = await apollo.query({
    query: GET_SITES,
    variables,
  });
  return response.data.sites;
};

const getCountCampaigns = async (variables) => {
  const response = await apollo.query({
    query: COUNT_CAMPAINGS,
    variables,
  });
  return response.data.count_campaigns.count;
};

const getAllCampaignsData = async (variables) => {
  const response = await apollo.query({
    query: GET_ALL_CAMPAINGS,
    variables,
  });
  return response.data.campaigns;
};

export { getSitesData, getCountCampaigns, getAllCampaignsData };
