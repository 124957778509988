import gql from "graphql-tag";

export const wrapperWorld = gql`
  query GetLocationWorld(
    $filter: String
    $groupCampaign: String
    $startDatetime: Int
    $endDatetime: Int
  ) {
    campaign(filter: $filter) {
      locations(
          group: $groupCampaign
          start_datetime: $startDatetime
          end_datetime: $endDatetime
        ) {
        _id {
          country
        }
        clicks
        views
        impressions
      }
    }
  }
`;