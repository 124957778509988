import gql from 'graphql-tag'
export const GET_TABLE_DAILY_DELIVERY = gql`
query Table_Daily_Delivery($filter: String) {
  campaign(filter: $filter) {
    campaign_id
    campaign_name
    campaign_start_datetime
    campaign_end_datetime
    sites {
      site_id
      site_name
      channels {
        channel_id
        channel_descr
        placements {
          placement_id
          placement_name
           purchase_type {
            purchase_type_format
          }
          format {
            format_size_width
            format_size_height
          }
          creatives {
            creative_id
            creative_has_file
            creative_group {
              group_name
            }
            data_by_date {
              impressions
              clicks
              completes
              _id {
                datetime
              }
            }
          }
         
        }
      }
    }
  }
}`