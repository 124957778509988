import gql from "graphql-tag";

export const map = gql`
  query Map($filter: String) {
    campaign(filter: $filter) {
      locations {
        _id {
          country
          region
        }
      }
    }
  }
`;
