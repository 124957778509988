import {
  getTablePerDayChannels,
  getTablePerDayPlacements,
  getTablePerDayCreatives,
} from "@/services/singleCampaign/tablePerDay";

const tablePerDay = {
    namespaced: true,
    state: {
      channels: {},
    },
    getters: {
      channels: (state) => state.channels,
    },
    mutations: {
      SET_CHANNELS(state, payload) {
        state.channels = payload
      }
    },
    actions: {
      async getChannels({ commit }, data) {
        const response = await getTablePerDayChannels(data);
        commit('SET_CHANNELS', data)
      }
    }
  }
  
  export default tablePerDay
  