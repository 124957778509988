const teamDataModule = {
  namespaced: true,
  state: {
    teamData: null,
  },
  getters: {
    teamData: (state) => state.teamData,
  },
  mutations: {
    SET_TEAM_DATA(state, payload) {
      state.teamData = payload
    },
  },
  actions: {
    async setTeamData({ commit }, data) {
      commit('SET_TEAM_DATA', data)
    }
  }
};

export default teamDataModule;
