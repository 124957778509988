import { AdvertisersData, AccountsData, TeamsData, getVisual } from '@/services'
import { getDataToken } from '@/services'
import { getNotification, readNotification, countNotification } from '@/services/notification'

const header = {
  namespaced: true,
  state: {
    searchValue: null,
    selectVehicle: null,
    dates: [],
    token: null,
    dialogToken: {},
    notifications: [],
    countUnreadNotifications: 0,
    countNotifications: 0,
    dateChannel: [],
  },
  getters: {
    searchValue: (state) => state.searchValue,
    selectVehicle: (state) => state.selectVehicle,
    dates: (state) => state.dates,
    token: (state) => state.token,
    dialogToken: (state) => state.dialogToken,
    notifications: (state) => state.notifications,
    countUnreadNotifications: (state) => state.countUnreadNotifications,
    countNotifications: (state) => state.countNotifications,
    dateChannel: (state) => state.dateChannel,
  },
  mutations: {
    SET_SEARCH(state, payload) {
      state.searchValue = payload ? payload : null
    },

    SET_SELECT_VEHICLE(state, payload) {
      state.selectVehicle = payload
    },

    SET_TOKEN(state, payload) {
      state.token = payload
    },

    SET_DIALOG_TOKEN(state, payload) {
      state.dialogToken = {
        messageToken: payload.messageToken,
        show: true,
      }
    },

    SEARCH_DATE(state, payload) {
      const sortedDates = payload.sort();
      if (sortedDates.length == 2) {
        state.dates = sortedDates;
      } else {
        state.dates = [];

      }
    },

    GET_NOTIFICATIONS(state, payload) {
      state.notifications = payload
    },

    UPDATE_NOTIFICATIONS(state, payload) {
      const index = state.notifications.findIndex(i => {
        if(i.notification_id === payload.notification_id){
          return i
        }
      });
      
      if (index !== -1) {
        state.notifications[index].notification_readings = [payload];
      }
    },

    COUNT_NOTIFICATIONS(state, payload) {
      state.countNotifications = payload
    },

    COUNT_UNREADNOTIFICATIONS(state, payload) {
      state.countUnreadNotifications = payload
    },

    SET_DATECHANNEL(state, payload) {
        state.dateChannel.push(payload)
    },

    CLEAR_DATECHANNEL(state, payload) {
      state.dateChannel = []
    },
  },
  actions: {
    async getInputSearch({ commit }, payload) {
      commit('SET_SEARCH', payload)
    },

    async getSelectVehicle({ commit }, payload) {
      commit('SET_SELECT_VEHICLE', payload)
    },

    async getDate({ commit }, payload) {
      commit('SEARCH_DATE', payload)
    },

    async getToken({ commit }, payload) {
      var variablesToken = {
        campaignId: payload.campaignId,
        siteId: payload.siteId,
        redirect: payload.redirect,
      };

      try {
        const response = await getDataToken(variablesToken);
        commit('SET_TOKEN', response)
      } catch (error) {
        commit('SET_TOKEN', null)
        console.log(error)
      }
    },

    async getAllNotification({ commit }, payload) {
      commit('GET_NOTIFICATIONS', [])
      var variables = {
        filter: null,
        orderby: "notification_id",
        direction: "DESC",
        offset: payload.offset,
        limit: payload.limit,
      };
      
      try {
        const response = await getNotification(variables);
        commit('GET_NOTIFICATIONS', response)
      } catch (error) {
        console.log(error)
      }
    },

    async getCountNotification({ commit }, payload) {
      var variables = {
        filter: null,
      };

      try {
        const response = await countNotification(variables);
        commit('COUNT_NOTIFICATIONS', response)
      } catch (error) {
        console.log(error)
      }
    },

    async getCountUnreadNotification({ commit }, payload) {
      commit('COUNT_UNREADNOTIFICATIONS', payload)
    },

    async createReadNotification({ commit }, payload) {
      try {
        const res = await readNotification(payload);
        commit('UPDATE_NOTIFICATIONS', res)
        var variables = {
          filter: null,
          orderby: null,
          direction: null,
          offset: null,
          limit: null,
        };
        // const response = await getNotification(variables);
      } catch (error) {
        console.log(error)
      }
    },

    changeDateChannel({ commit }, payload) {
        commit('SET_DATECHANNEL', payload)
    },

    clearDateChannel({ commit }) {
        commit('CLEAR_DATECHANNEL')
    }
  }
}

export default header