import gql from "graphql-tag";

export const GET_PURCHASES_TYPES_TOTAL = gql`
query GetPurchaseTypeInCampaign($filter: String, $startDatetime: Int, $endDatetime: Int, $totalDataEndDatetime2: Int, $totalDataStartDatetime2: Int) {
  campaign(filter: $filter) {
    total_data(end_datetime: $totalDataEndDatetime2, start_datetime: $totalDataStartDatetime2) {
      viewability
      viewables
      blocklists_percent
    }
    purchases(start_datetime: $startDatetime, end_datetime: $endDatetime) {
      cpm {
        quantity
        total_data {
          impressions
          viewability
          viewables
          blocklists_percent
          blocklists
          completes
        }
      }
      cpc {
        quantity
        total_data {
          clicks
          blocklists_percent
          blocklists
          viewability
          viewables
          completes
        }
      }
      cpv {
        quantity
        total_data {
          views
          blocklists_percent
          blocklists
          viewability
          viewables
          completes
        }
      }
    }
  }
}
`;

export const GET_PURCHASES_TYPES_TOTAL_SITE = gql`
query GetPurchaseTypeInSite($filter: String, $startDatetime: Int, $endDatetime: Int, $totalDataEndDatetime2: Int, $totalDataStartDatetime2: Int, $campaignId: Int!) {
  site(filter: $filter) {
    total_data(end_datetime: $totalDataEndDatetime2, start_datetime: $totalDataStartDatetime2, campaign_id: $campaignId) {
      viewability
      viewables
      blocklists_percent
    }
    purchases(start_datetime: $startDatetime, end_datetime: $endDatetime, campaign_id: $campaignId) {
      cpm {
        quantity
        total_data {
          impressions
          viewability
          viewables
          blocklists_percent
          blocklists
          completes
        }
      }
      cpc {
        quantity
        total_data {
          clicks
          blocklists_percent
          blocklists
          viewability
          viewables
          completes
        }
      }
      cpv {
        quantity
        total_data {
          views
          blocklists_percent
          blocklists
          viewability
          viewables
          completes
        }
      }
    }
  }
}
`;
