// import { logout } from '@/services/auth-service'

const auth = {
  namespaced: true,
  state: {
    user: null,
    load: false,
    emailUser: null
  },
  getters: {
    user: (state) => state.user,
    load: (state) => state.load
  },
  mutations: {
    EMAIL_USER(state, payload) {
      state.emailUser = payload
    },
    LOGGED_USER(state, payload) {
      state.user = payload
    },
    LOGOUT_USER(state, payload) {
      state.user = payload
    }
  },
  actions: {
    setEmail({commit}, email){
      commit('EMAIL_USER', email)
    },
    getUser({ commit }, data) {
      commit('LOGGED_USER', data)
    },
    /* async logoutUser({ commit }) {
      const response = await logout()
      if (response.code == '200') {
        commit('LOGOUT_USER', null)
        localStorage.clear()
      }
    } */
  }
}

export default auth
