import apollo, { onLogin } from "@/plugins/apollo";
import { LOGIN } from "@/graphql/Auth/Login"
import { LOGOUT } from "@/graphql/Auth/Logout"
import { SEND_CODE } from "@/graphql/Auth/SendCode"
import { INSERT_CODE } from "@/graphql/Auth/InsertCode"
import { LOGGED_USER } from "@/graphql/Auth/LoggedUser";
import { RECOVER_PASSWORD } from "@/graphql/Auth/RecoverPassword";

const login = async variables => {
    const response = await apollo.mutate({
        mutation: LOGIN,
        variables
    })
    const { login } = response.data
    const sessionId = JSON.parse(login.data)
    await onLogin(apollo, sessionId.sessionId)
    return login
}

const logout = async () => {
    const response = await apollo.mutate({
        mutation: LOGOUT,
    })
    return response
}

const sendCode = async (userEmail) => {
    const response = await apollo.mutate({
        mutation: SEND_CODE,
        variables: {
            userEmail,
        },
    })
    return response
}

const insertCode = async variables => {
    const response = await apollo.mutate({
        mutation: INSERT_CODE,
        variables
    })
    const { login } = response.data
    const sessionId = JSON.parse(login.data)
    await onLogin(apollo, sessionId.sessionId)
    return login
}

const loggedUser = async (options = {}) => {
    const response = await apollo.query({
        query: LOGGED_USER,
        ...options
    })
    return response.data.logged_user
}

const recoverPassword = async (variables) => {
    const response = await apollo.mutate({
        mutation: RECOVER_PASSWORD,
        variables
    })
    return response
}

export default {
    login,
    sendCode,
    insertCode,
    loggedUser,
    recoverPassword,
    logout
}