const midInteractions = {
  namespaced: true,
  state: {
    dataMid: null,
  },
  getters: {
    dataMid: (state) => state.dataMid,
  },
  mutations: {
    INTERACTION_MID(state, payload) {
      state.dataMid = payload;
    },
  },
  actions: {
    setInteractionsMID({ commit }, dataMid) {
      if (dataMid.total_data.mid) {
        commit("INTERACTION_MID", dataMid);
      }
    },
  },
};

export default midInteractions;
