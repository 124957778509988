import { formatError } from "@/helpers/filters";
import AuthService from "../services/auth-service";

export default {
  name: "RecoverPassword",

  data: () => ({
    email: null,
    sendMessage: null,
    errorMessage: null,
    dialog: false,
    dialogError: false,
    shakeAnimated: false,
    rulesEmail: [
      (value) => !!value || "Obrigatório",
      (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Email inválido";
      },
    ],
  }),

  methods: {
    async recoveryPassword() {
      const variables = {
        userEmail: this.email,
        redirect: process.env.VUE_APP_URL,
        userPassword: null,
        passwordCheck: null,
      };
      if (!this.email) {
        this.shakeAnimated = true;
        setTimeout(() => {
          this.shakeAnimated = false;
        }, 1000);
      } else {
        try {
          const response = await AuthService.recoverPassword(variables);
          this.sendMessage = response.data.password_recovery.message;
          this.$store.state.user.email = this.email;
          this.dialog = true;
          setTimeout(() => {
            (this.dialog = false), this.$router.push("/");
          }, 3000);
        } catch (error) {
          this.errorMessage = formatError(error.message);
          this.dialogError = true;
          setTimeout(() => {
            this.dialogError = false;
          }, 3000);
        }
      }

      /*
        const path = "analytics.com";
        this.$apollo
          .mutate({
            mutation: RECOVERY_PASSWORD_MUTATION,
            variables: {
              userEmail: this.email,
              redirect: path,
              userPassword: null,
              passwordCheck: null,
            },
          })
          .then((data) => {
            if (data.data.password_recovery.code == 200) {
              this.messageSend = data.data.password_recovery.message;
              (this.dialog = true),
                setTimeout(() => {
                  this.dialog = false;
                  this.$router.push("/");
                }, 3000);
            }
          })
          .catch((error) => {
            this.errorMessage = error.message.substr(14);
            (this.dialogError = true),
              setTimeout(() => {
                this.dialogError = false;
              }, 3000);
          });
      }
      */
    },
  },
};
