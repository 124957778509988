import gql from "graphql-tag";

export const domainOverlay = gql`
  query domainOverlay($filter: String) {
    campaign(filter: $filter) {
      campaign_name
      domains_overlay
    }
  }
`;
