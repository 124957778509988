import gql from 'graphql-tag'
export const GET_CREATIVE_HEATMAPS = gql`
query GetHeatmapsCreative(
    $filter: String
    $startDatetime: Int
    $endDatetime: Int
  ) {
    creatives(filter: $filter) {
    placement {
      purchase_type {
        purchase_type_name
      }
    }
    heatmaps(start_datetime: $startDatetime, end_datetime: $endDatetime) {
      _id {
        x
        y
      }
      move
    }
  }
}
`