import gql from 'graphql-tag'

export const GET_TABLE_CPM = gql`
query CampaignCPM($filter: String, $campaignId: Int!, $purchaseType: String, $channelsCampaignId: Int!, $channelsFilter2: String, $purchaseTypePlacement: String, $startDatetime: Int, $endDatetime: Int, $totalDataStartDatetime2: Int, $totalDataEndDatetime2: Int, $totalDataStartDatetime3: Int, $totalDataEndDatetime3: Int, $totalDataStartDatetime4: Int, $totalDataEndDatetime4: Int) {
  campaign(filter: $filter)  {
    campaign_id
    sites {
      site_id
      site_name
      total_data(campaign_id: $campaignId, purchase_type: $purchaseType, start_datetime: $startDatetime, end_datetime: $endDatetime) {
        impressions
        blocklists
        unique_impressions_site_cpm
        unique_users
        clicks
        viewables
        views
        viewability
      }
      channels(campaign_id: $channelsCampaignId, filter: $channelsFilter2)  {
        channel_id
        channel_descr
        channel_purchased_quantity
        total_data(start_datetime: $totalDataStartDatetime2, end_datetime: $totalDataEndDatetime2) {
          impressions
          blocklists
          unique_impressions_site_cpm
          unique_users
          clicks
          viewables
          views
          viewability
        }
        placements {
          placement_id
          placement_name
          purchase_type(filter: $purchaseTypePlacement) {
            purchase_type_format
          }
          placement_status {
            placement_status_id
            status_descr
            status_color
          }
          format {
            format_size_width
            format_size_height
            format_name
          }
          total_data(start_datetime: $totalDataStartDatetime3, end_datetime: $totalDataEndDatetime3) {
            impressions
            blocklists
            unique_impressions_placement_cpm
            unique_users
            clicks
            viewables
            views
            viewability
          }
          creatives {
            creative_id
            total_data(start_datetime: $totalDataStartDatetime4, end_datetime: $totalDataEndDatetime4) {
              impressions
              blocklists
              unique_impressions
              unique_users
              clicks
              viewables
              views
              viewability
            }
            creative_group {
              group_name
            }
          }
        }
      }
   }
  }
}`