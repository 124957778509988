import gql from 'graphql-tag'

export const GET_ALL_CAMPAINGS = gql` 
query GetAllCampaigns($orderby: String, $offset: Int, $limit: Int, $direction: String, $filter: String) {
  campaigns(orderby: $orderby, offset: $offset, limit: $limit, direction: $direction, filter: $filter) {
    campaign_id
    campaign_name
    campaign_description
    team_id
    account {
      account_id
      account_name
    }
    advertiser {
      account_id
      account_name
    }
    campaign_status {
      status_descr
    }
    team {
      team_id
      team_name
    }
  }
}`