import { getDataCampaign } from "@/services";
import { getSitesData } from "@/services/allCampaigns";
import { getAllCampaignsData } from "@/services/allCampaigns";
import { getCountCampaigns } from "@/services/allCampaigns";

const dashboard = {
  namespaced: true,
  state: {
    campaignHeader: null,
    sites: null,
    allCampaignsData: null,
    allCountCampaigns: null,
    loading: false
  },
  getters: {
    campaignHeader: (state) => state.campaignHeader,
    sites: (state) => state.sites,
    allCampaignsData: (state) => state.allCampaignsData,
    allCountCampaigns: (state) => state.allCountCampaigns,
    loading: (state) => state.loading,
  },
  mutations: {
    SET_CAMPAIGN_HEADER(state, payload) {
      state.campaignHeader = payload;
    },
    SET_SITES(state, payload) {
      state.sites = payload;
    },
    SET_ALL_CAMPAIGNS_DATA(state, payload) {
      state.allCampaignsData = payload;
    },
    SET_COUNT_CAMPAIGNS(state, payload) {
      state.allCountCampaigns = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    async getCampaignHeader({ commit }, variables) {
      const response = await getDataCampaign(variables);
      commit("SET_CAMPAIGN_HEADER", response);
    },
    async getSite({ commit }, variables) {
      const response = await getSitesData(variables);
      commit("SET_SITES", response);
    },
    async getAllCampaignsData({ commit }, variables) {
      const response = await getAllCampaignsData(variables);
      commit("SET_ALL_CAMPAIGNS_DATA", response);
    },
    async getCountCampaigns({ commit }, variables) {
      const response = await getCountCampaigns(variables);
      commit("SET_COUNT_CAMPAIGNS", response);
    },
    async setLoading({ commit }, variables) {
      commit("SET_LOADING", variables);
    },
  },
};

export default dashboard;
