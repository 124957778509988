import gql from "graphql-tag";

export const chartVerification = gql`
query GetUrlsSiteInCampaing($campaignId: Int!, $filterSite: String, $filter: String, $campaignFilter2: String, $group: String, $offset: Int, $limit: Int, $startDatetime: Int, $endDatetime: Int) {
  campaign(filter: $campaignFilter2) {
    sites(offset: $offset, limit: $limit, filter: $filterSite) {
      site_id
      site_name
      urls(campaign_id: $campaignId, filter: $filter, group: $group, start_datetime: $startDatetime, end_datetime: $endDatetime) {
        views
        impressions
        clicks
        _id {
          category
        }
      }
    }
  }
}
`;
