import gql from 'graphql-tag'

export const GET_SITES = gql`
query GetSites($filter: String, $orderby: String, $offset: Int, $limit: Int, $direction: String) {
  sites(filter: $filter, orderby: $orderby, offset: $offset, limit: $limit, direction: $direction) {
    site_id
    site_name
  }
}`

export const GET_SITES_CAMPAIGN = gql`
query GetSitesInCampaign($filter: String) {
  campaign (filter: $filter) {
    sites {
      site_id
      site_name
    }
  }
}`
