import { formatError } from "@/helpers/filters";
import AuthService from "../services/auth-service";

export default {
  name: "InsertCode",

  data: () => ({
    dialogError: false,
    errorMessage: null,
    shakeAnimated: false,
    code: '',
    userEmail: '',
    rulesCode: [
      (value) => !!value || "Obrigatório",
      (value) => (value && value.length >= 6) || "Min 6 caracteres",
    ],
  }),
  methods: {
   async insertCode(){
      const variables = {
        userEmail: this.$store.state.user.email,
        code: this.code,
      }
      if (!variables.userEmail) {
        this.shakeAnimated = true;
        setTimeout(() => { this.shakeAnimated = false }, 1000);
      } else {
        try {
          await AuthService.insertCode(variables)
          this.$router.push("dashboard/campaigns")
        } catch (error) {
          this.errorMessage = formatError(error.message)
          this.dialogError = true
          setTimeout(() => { this.dialogError = false }, 3000)
        }
      }
    }
  },
};