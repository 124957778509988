import gql from 'graphql-tag'

export const GET_PLACEMENTS = gql`
query GetCreativeInPlacements($filter: String, $placementsFilter2: String) {
  campaign(filter: $filter) {
    placements(filter: $placementsFilter2) {
      placement_id
      format {
        format_size_height
        format_size_width
        format_type_id
      }
      creatives {
        creative_id
        creative_group {
          group_name
        }
      }
    }
  }
}`