export default {
  name: "Footer",

  data: () => ({}),
  computed: {
    dataTeams() {
      return this.$store.state.teamData;
    },
  },
};
