import Authentication from "@/views/Auth/Authentication.vue"

export default {
    path: '/',
    component: Authentication,
    meta: { requiresAuth: false },
    children: [
        {
            path : '/',
            name: 'Login',
            component: () => import('@/components/Auth/Login/Login.vue'),      
        },
        {
            path : 'send-code',
            name: 'SendCode',
            component: () => import('@/components/Auth/SendCode/SendCode.vue'),
        },
        {
            path : 'insert-code',
            name: 'InsertCode',
            component: () => import('@/components/Auth/InsertCode/InsertCode.vue'),
        },
        {
            path : 'recover-password',
            name: 'RecoverPassword',
            component: () => import('@/components/Auth/RecoverPassword/RecoverPassword.vue'),
        },
    ]
}