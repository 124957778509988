import gql from "graphql-tag";

export const pressVehicle = gql`
  query Vehicle(
    $filter: String
    $campaignId: Int!
    $startDatetime: Int
    $endDatetime: Int
  ) {
    campaign(filter: $filter) {
      browsers {
        _id {
          os_name
          os_type
        }
        impressions
        views
      }
      locations {
        _id {
          country
          region
        }
        clicks
        views
        impressions
      }
      total_data {
        impressions
        views
      }
      sites {
        site_name
        locations(
          campaign_id: $campaignId
          start_datetime: $startDatetime
          end_datetime: $endDatetime
        ) {
          _id {
            country
            region
          }
          views
          impressions
        }
      }
    }
  }
`;
