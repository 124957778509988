import { getDataCampaign } from "@/services";
import { getAllCampaignsData } from "@/services/allCampaigns";
import { getCountCampaigns } from "@/services/allCampaigns";
import { getWrapperWorld, getTableWorld } from "@/components/SingleCampaign/services/index"
import { getTableMap, getTableMapSite } from "@/components/SingleCampaign/services/index";
import { campaignBrowser, siteBrowser } from "@/services/singleCampaign";
import { getDomainOverlay } from "@/components/SingleCampaign/services/index";

const campaign = {
  namespaced: true,
  state: {
    campaigns: [],
    campaign: {},
    countCampaigns: 0,
    dataWorld: null,
    tableDataWorld: null,
    dataBr: null,
    tableDataBr: null,
    browsers: null,
    domainOverlay: null,
    report: false,
  },
  getters: {
    campaigns: (state) => state.campaigns,
    campaign: (state) => state.campaign,
    countCampaigns: (state) => state.countCampaigns,
    dataWorld: (state) => state.dataWorld,
    tableDataWorld: (state) => state.tableDataWorld,
    dataBr: (state) => state.dataBr,
    tableDataBr: (state) => state.tableDataBr,
    browsers: (state) => state.browsers,
    domainOverlay: (state) => state.domainOverlay,
    report: (state) => state.report,
  },
  mutations: {
    SET_CAMPAIGNS(state, payload) {
      state.campaigns = payload
    },

    SET_CAMPAIGN(state, payload) {
      state.campaign = payload
    },

    SET_COUNT_CAMPAIGNS(state, payload) {
      state.countCampaigns = payload;
    },

    SET_COUNT_CAMPAIGNS(state, payload) {
      state.countCampaigns = payload;
    },

    SET_WORLD(state, payload) {
      state.dataWorld = payload;
    },

    SET_TABLE_WORLD(state, payload) {
      state.tableDataWorld = payload;
    },

    SET_BR(state, payload) {
      state.dataBr = payload;
    },

    SET_TABLE_BR(state, payload) {
      state.tableDataBr = payload;
    },

    SET_BROWSERS(state, payload) {
      state.browsers = payload;
    },
    
    SET_DOMAIN_OVERLAY(state, payload) {
      state.domainOverlay = payload;
    },
    
    CLEAR_DOMAIN_OVERLAY(state, payload) {
      state.domainOverlay = null;
    },
    
    GENERATE_REPORT(state, payload) {
      state.report = payload;
    },
  },
  actions: {
    async getCampaigns({ commit }, variables) {
      const response = await getAllCampaignsData(variables)
      commit('SET_CAMPAIGNS', response)
    },

    async findCampaign({ commit }, variables) {
      const response = await getDataCampaign(variables);
      commit('SET_CAMPAIGN', response)
    },

    async getCountCampaigns({ commit }, variables) {
      const response = await getCountCampaigns(variables);
      commit("SET_COUNT_CAMPAIGNS", response);
    },

    async getWorld({ commit }, variables) {
      commit("SET_WORLD", []);
      const response = await getWrapperWorld(variables);
      commit("SET_WORLD", response);
    },

    async getTableMapWorld({ commit }, variables) {
      commit("SET_TABLE_WORLD", []);
      const response = await getTableWorld(variables);
      commit("SET_TABLE_WORLD", response);
    },

    async getDataBr({ commit }, variables) {
      commit("SET_BR", []);
      const response = await getTableMap(variables);
      commit("SET_BR", response);
    },

    async getTableBr({ commit }, variables) {
      const response = await getTableMap(variables);
      commit("SET_TABLE_BR", response);
    },
    async getTableBrSite({ commit }, variables) {
      const response = await getTableMapSite(variables);
      commit("SET_TABLE_BR", response);
    },
/* 
    async getTableBr({ commit }, variables) {
      const response = await getTableMap(variables);
      commit("SET_TABLE_BR", response);
    }, */

    async getBrowser({ commit }, variables) {
      const response = await campaignBrowser(variables);
      commit("SET_BROWSERS", response);
    },

    async getBrowserSite({ commit }, variables) {
      const response = await siteBrowser(variables);
      commit("SET_BROWSERS", response);
    },

    async getDomain({ commit }, variables) {
      const response = await getDomainOverlay(variables);
      commit("SET_DOMAIN_OVERLAY", response);
    },

    async clearDomain({ commit }, variables) {
      commit("CLEAR_DOMAIN_OVERLAY",null);
    },
    
    generateReport({ commit }, data) {
      commit("GENERATE_REPORT",data);
    },
  },
};

export default campaign;
