import apollo from "@/plugins/apollo";
import { GET_NOTIFICATION } from "@/graphql/Dashboard/Notification";
import { READ_NOTIFICATION } from "@/graphql/Dashboard/NotificationRead";
import { COUNT_NOTIFICATION } from "@/graphql/Dashboard/NotificationCount";

const getNotification = async (variables) => {
    try {
      const response = await apollo.query({
        query: GET_NOTIFICATION,
        variables,
      });
      return response.data.notifications;
    } catch (error) {
      console.error("Erro ao obter dados de compras por tipo:", error);
      throw error;
    }
};

const readNotification = async (content) => {
    try {
      const response = await apollo.mutate({
        mutation: READ_NOTIFICATION,
        variables: content
    })
    
      return response.data.createNotificationRead.data;
    } catch (error) {
      console.error("Erro ao obter dados de compras por tipo:", error);
      throw error;
    }
};

const countNotification = async (variables) => {
    try {
      const response = await apollo.query({
        query: COUNT_NOTIFICATION,
        variables
      })
      
      return response.data.count_notifications;
    } catch (error) {
      console.error("Erro ao obter dados:", error);
      throw error;
    }
};

export {
    getNotification,
    readNotification,
    countNotification
};
  