import apollo from "@/plugins/apollo";
import { GET_PURCHASES_TYPES_TOTAL, GET_PURCHASES_TYPES_TOTAL_SITE } from "@/graphql/Dashboard/purchaseTypeData";
import { sensitiveUrlTable } from "@/graphql/Dashboard/sensitiveUrlTable";
import { TABLE_VERIFICATION_DATA } from "@/graphql/Dashboard/TableVerification";
import { GET_SITES_CAMPAIGN } from "@/graphql/Dashboard/Sites";
import { GET_CATEGORIES } from "@/graphql/Dashboard/Categorizes";
import { GET_BROWSER,GET_BROWSER_SITE } from "@/graphql/Dashboard/getBrowser";
import { GET_TABLE_REPORTS } from "@/graphql/Dashboard/TableReports";

import { GET_SESSIONS_SITE } from "@/graphql/Dashboard/sessionCampaign";
import { GET_IPS_SITE } from "@/graphql/Dashboard/IpCampaign";

import {
  GET_TABLE_CHANNELS,
  GET_TABLE_PLACEMENTS,
  GET_TABLE_CREATIVES,
} from "@/graphql/Dashboard/GeneralTable";



const getPurchasesTypesTotalSite = async (variables) => {
  try {
    const response = await apollo.query({
      query: GET_PURCHASES_TYPES_TOTAL_SITE,
      variables,
    });
    return response.data.site;
  } catch (error) {
    console.error("Erro ao obter dados de compras por tipo:", error);
    throw error;
  }
};
const getPurchasesTypesTotal = async (variables) => {
  try {
    const response = await apollo.query({
      query: GET_PURCHASES_TYPES_TOTAL,
      variables,
    });
    return response.data.campaign;
  } catch (error) {
    console.error("Erro ao obter dados de compras por tipo:", error);
    throw error;
  }
};

const getSensitiveUrl = async (variables) => {
  try {
    const response = await apollo.query({
      query: sensitiveUrlTable,
      variables,
    });
    return response.data.campaign;
  } catch (error) {
    console.error("Erro ao obter dados das urls sensiveis:", error);
  }
};

const table_verification_getData = async (variables) => {
  const response = await apollo.query({
    query: TABLE_VERIFICATION_DATA,
    variables,
  });
  return response.data.urls;
};

const table_verification_getData_count = async (variables) => {
  const response = await apollo.query({
    query: TABLE_VERIFICATION_DATA,
    variables,
  });
  return response.data.urls;
};

const categoriesData = async () => {
  const response = await apollo.query({
    query: GET_CATEGORIES,
  });
  return response.data;
};

const sitesDataCampaign = async (variables) => {
  const response = await apollo.query({
    query: GET_SITES_CAMPAIGN,
    variables,
  });
  return response.data.campaign.sites;
};

const campaignBrowser = async (variables) => {
  const response = await apollo.query({
    query: GET_BROWSER,
    variables,
  });
  
  return response.data.campaign;
};

const siteBrowser = async (variables) => {
  const response = await apollo.query({
    query: GET_BROWSER_SITE,
    variables,
  });
  
  return response.data.site;
};

const Reports_getData = async (variables) => {
  const response = await apollo.query({
    query: GET_TABLE_REPORTS,
    variables,
    fetchPolicy: "network-only",
  });
  return response.data.reports;
};

const getChannelGeneralTable = async (variables) => {
  const response = await apollo.query({
    query: GET_TABLE_CHANNELS,
    variables,
  });
  return response.data.campaign.channels;
};
const getSiteGeneralTableNew = async (variables) => {
  const response = await apollo.query({
    query: GET_TABLE_CHANNELS,
    variables,
  });
  return response.data.campaign.sites;
};

const getPlacementsGeneralTable = async (variables) => {
  try {
    const response = await apollo.query({
      query: GET_TABLE_PLACEMENTS,
      variables,
    });
    return response.data.channel.placements;
  } catch (error) {}
};

const getCreativesGeneralTable = async (variables) => {
  try {
    const response = await apollo.query({
      query: GET_TABLE_CREATIVES,
      variables,
    });
    return response.data.placement.creatives;
  } catch (error) {}
};

const getSessions = async (variables) => {
  try {
    const response = await apollo.query({
      query: GET_SESSIONS_SITE,
      variables,
    });
    return response.data.campaign.sites;
  } catch (error) {}
};

const getIps = async (variables) => {
  try {
    const response = await apollo.query({
      query: GET_IPS_SITE,
      variables,
    });
    return response.data.campaign.sites;
  } catch (error) {}
};

export {
  getPurchasesTypesTotal,
  getSensitiveUrl,
  categoriesData,
  sitesDataCampaign,
  table_verification_getData,
  table_verification_getData_count,
  campaignBrowser,
  Reports_getData,
  getChannelGeneralTable,
  getSiteGeneralTableNew,
  getPlacementsGeneralTable,
  getCreativesGeneralTable,
  getSessions,
  getIps,
  getPurchasesTypesTotalSite,
  siteBrowser
};
