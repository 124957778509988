import Login from "@/components/Auth/Login/Login.vue"
import SendCode from "@/components/Auth/SendCode/SendCode.vue"
import InsertCode from "@/components/Auth/InsertCode/InsertCode.vue"
import RecoverPassword from "@/components/Auth/RecoverPassword/RecoverPassword.vue"
  
export default {
    name: 'Authentication',

    components: {
        Login,
        SendCode,
        InsertCode,
        RecoverPassword,
    },
   
    data: () => ({
        backgroundImagePrimary: '',
        backgroundImageSecondary: '',
        cssProps: {
            backgroundImage:'',
        },
    }),
    methods: {
        randomImage(backgrounds){
            let randomImg = backgrounds[Math.floor(Math.random() * backgrounds.length)]
            this.cssProps.backgroundImage = `url(${randomImg})`
        }
    },
   async created(){
        try {
            const backgrounds = [this.$store.state.teamData.team_bg_1, this.$store.state.teamData.team_bg_2]
            this.randomImage(backgrounds)
        } catch (error) {
        }
    }
}