import apollo from "@/plugins/apollo";
import {
  TABLE_PER_DAY_CHANNELS,
  TABLE_PER_DAY_CREATIVES,
  TABLE_PER_DAY_PLACEMENTS,
  TABLE_PER_DAY_SITES,
  GET_STATUS_SITE
} from "@/graphql/Dashboard/TablePerDay";

const fetchData = async (query, variables) => {
  try {
    const response = await apollo.query({
      query,
      variables,
    });

    return response.data;
  } catch (error) {
    throw new Error(`Error fetching data: ${error.message}`);
  }
};

const getStatusSite = async (variables) => {
  const data = await fetchData(GET_STATUS_SITE, variables);
  return data.site;
};

const getTablePerDaySites = async (variables) => {
  const data = await fetchData(TABLE_PER_DAY_SITES, variables);
  return data.campaign.sites;
};

const getTablePerDayChannels = async (variables) => {
  const data = await fetchData(TABLE_PER_DAY_CHANNELS, variables);
  return data.site;
};

const getTablePerDayPlacements = async (variables) => {
  const data = await fetchData(TABLE_PER_DAY_PLACEMENTS, variables);
  return data.channel.placements;
};

const getTablePerDayCreatives = async (variables) => {
  const data = await fetchData(TABLE_PER_DAY_CREATIVES, variables);
  return data.placement.creatives;
};

export {
  getStatusSite,
  getTablePerDaySites,
  getTablePerDayChannels,
  getTablePerDayPlacements,
  getTablePerDayCreatives,
};
