
import { formatError } from "@/helpers/filters";
import AuthService from "../services/auth-service";

export default {
  name: "SendCode",
  components: {},

  data: () => ({
    email: null,
    dialog: false,
    dialogError: false,
    shakeAnimated: false,
    sendMessage: null,
    errorMessage: null,
    rulesEmail: [
      (value) => !!value || "Obrigatório",
      (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Email inválido";
      },
    ],
  }),
  methods: {
    async submitCode() {
      if (!this.email) {
        this.shakeAnimated = true;
        setTimeout(() => { this.shakeAnimated = false }, 1000);
      } else {
        try {
          const response = await AuthService.sendCode(this.email)
          this.sendMessage = response.data.login.message
          this.$store.state.user.email = this.email;
          this.dialog = true
          setTimeout(() => {
            this.dialog = false,
            this.$router.push("/insert-code") }, 3000)
        } catch (error) {
          this.errorMessage = formatError(error.message)
          this.dialogError = true
          setTimeout(() => { this.dialogError = false }, 3000)
        }
      }
    },
  },
};