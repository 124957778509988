import gql from "graphql-tag";

export const GET_IPS_SITE = gql`
query GetIpsCampaign($filter: String, $filterSite: String, $campaignId: Int!) {
    campaign(filter: $filter) {
      campaign_id
      campaign_name
      sites(filter: $filterSite) {
        site_id
        site_name
        ips (campaign_id: $campaignId) {
          _id {
            ip
          }
          requests
        }
      }
    }
  }
`;
