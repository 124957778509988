import apollo from "@/plugins/apollo";
import { GET_TOKEN } from "@/graphql/Auth/Token";
import { DATA_HEADER_CAMPAING } from "@/graphql/Dashboard/Header";
import { DATA_TEAM } from "@/graphql/Dashboard/DataTeams"

const teamData = async variables => {
    const response = await apollo.query({
        query: DATA_TEAM,
        variables
    })
    return response.data.team
}

const getDataCampaign = async (variables) => {
  const response = await apollo.query({
    query: DATA_HEADER_CAMPAING,
    variables,
  });
  return response.data.campaign;
};

const getDataToken = async (variables) => {
  const response = await apollo.query({
    query: GET_TOKEN,
    variables,
  });
  return response.data.user_token;
};

export { teamData, getDataCampaign, getDataToken };
