import gql from 'graphql-tag'

export const GET_TOKEN = gql`
query User_token($campaignId: Int!, $redirect: String!, $siteId: Int) {
  user_token(campaign_id: $campaignId, redirect: $redirect, site_id: $siteId) {
    code
    data
    message
  }
}
`