import gql from 'graphql-tag'

export const GET_NOTIFICATION= gql`
query GetNotifications($filter: String, $orderby: String, $direction: String, $offset: Int, $limit: Int) {
    notifications(filter: $filter, orderby: $orderby, direction: $direction, offset: $offset, limit: $limit) {
      notification_datetime
      notification_id
      notification_platform_id
      notification_text
      notification_title
      notification_readings {
        datetime
        notification_id
        user_id
      }
    }
  }`