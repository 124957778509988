import gql from 'graphql-tag'

export const TABLE_VERIFICATION_DATA = gql`
query GetUrlsVerification($campaignId: Int!, $offset: Int, $limit: Int, $count: Boolean, $filter: String, $startDatetime: Int, $endDatetime: Int) {
  urls(campaign_id: $campaignId, offset: $offset, limit: $limit, count: $count, filter: $filter, start_datetime: $startDatetime, end_datetime: $endDatetime) {
    _id {
      site_id
      site {
        site_id
        site_name
      }
      placement_id
      placement {
        placement_id
        placement_name
      }
      url
      category
    }
    impressions
    clicks
    views
    count
  }
}`